'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import type { EntryClickMenuEventData, MenuEntry } from 'header/types/menu'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { CommonGenericEvents } from '../../../../constants'
import { InspirationalButtonLink } from '../InspirationalLinkBase/InspirationalButtonLink/InspirationalButtonLink'

type EntrySubmenuProps = {
	section: MenuEntry
	className: string
	isSelected: boolean | undefined
	dataTestIdCategory: string
	eventData: EntryClickMenuEventData
	onClick: () => void
	handleKeyDown?: (event: React.KeyboardEvent) => void
}
export function EntrySubmenu({
	section,
	className,
	isSelected,
	dataTestIdCategory,
	eventData,
	onClick,
	handleKeyDown,
}: EntrySubmenuProps) {
	const { id, labelId } = section
	const { t } = useLabels()
	const { isLargeOrGreater } = useResponsive()

	const handleClick = () => {
		if (!isLargeOrGreater) {
			sendGenericEvent(CommonGenericEvents.CLICK_MENU, eventData)
		}
		onClick()
	}

	return (
		<InspirationalButtonLink
			className={className}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			data-testid={`menu.${dataTestIdCategory}.${id}.family`}
			active={isSelected}
			aria-expanded={isSelected}
		>
			{t(labelId)}
		</InspirationalButtonLink>
	)
}
