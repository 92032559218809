'use client'

import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { MY_ACCOUNT_LINK_HREF } from '../constants/myAccountLink'

export type MyAccountClientLinkProps = Omit<ClientLinkProps, 'featureFlagKey'>

export function MyAccountClientLink({
	children,
	...linkProps
}: Omit<ClientLinkProps, 'href'>) {
	const props: Omit<ClientLinkProps, 'prefetch'> = {
		...linkProps,
		children,
		href: MY_ACCOUNT_LINK_HREF,
		withLegacyLocale: false,
	}
	return <ClientLink {...props}>{children}</ClientLink>
}
