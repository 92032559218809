'use client'

import { HoverDelayInspiration } from 'header/constants/hoverDelays'
import { TimeOut } from 'header/constants/timeOuts'
import { useHover } from 'header/hooks/useHover'
import { useMenus } from 'header/hooks/useMenus'
import { useMenuService } from 'header/services/menu/client'
import type { MenuService } from 'header/types/menu'
import { hideInSections } from 'header/utils/menu/hideInSections'
import { useHeaderState } from 'hooks/useHeaderContext/useHeaderState'
import { useClientLabels } from 'labels/hooks/useClientLabels/useClientLabels'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useSetLabels } from 'labels/hooks/useSetLabels/useSetLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect, useState } from 'react'
import { Responsive } from 'responsive/components/Responsive/Responsive'
import { fetcher } from 'services/rest/fetcher'
import useSWRImmutable from 'swr/immutable'
import { LabelsNamespaces } from 'types/labels'
import { applyStylesIf, cx } from 'utils/cx'

import { OpenMenu } from './OpenMenu/OpenMenu'
import { StructureLPlus } from './Structure/StructureLPlus'
import { StructureSM } from './Structure/StructureSM'

import styles from './InspirationalMenu.module.scss'

export function InspirationalMenu() {
	const { getMenuServiceUrlClient } = useMenuService()
	const { open, firstOpen, updateMenus, updateOpen } = useMenus()
	const id = 'menuBackDrop'
	const [openFixed, setOpenFixed] = useState(false)
	const { isSearchOpen } = useHeaderState()

	const {
		isShop,
		country: { countryISO, languageISO },
	} = useMasterData()
	const { labels: layoutLabels } = useLabels()
	const setLabels = useSetLabels()

	const { data } = useSWRImmutable<MenuService>(
		firstOpen ? getMenuServiceUrlClient() : null,
		fetcher
	)

	const menusData = hideInSections(data?.menus)

	const menuNamespace = isShop
		? LabelsNamespaces.Menu_Shop
		: LabelsNamespaces.Menu_Outlet

	const { labels: labelsClient } = useClientLabels({
		namespaces: firstOpen ? [menuNamespace, LabelsNamespaces.Menu_Common] : [],
		suspense: false,
		countryISO,
		languageISO,
	})

	const handleOnMouseEnter = () => {
		if (openFixed) {
			updateOpen(false)
		}
	}

	const { ref } = useHover<HTMLDivElement>(
		handleOnMouseEnter,
		id,
		HoverDelayInspiration.OUTSIDE
	)

	useEffect(() => {
		updateMenus(menusData)
	}, [menusData])

	useEffect(() => {
		if (layoutLabels && labelsClient) {
			setLabels({
				...layoutLabels,
				...labelsClient,
			})
		}
	}, [labelsClient])

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				setOpenFixed(true)
			}, TimeOut.HOVER_OUTSIDE)
		} else {
			setOpenFixed(false)
		}
	}, [open])

	if (isSearchOpen) {
		return null
	}

	return (
		<div
			className={styles.menu}
			data-testid='inspirational.menu'
			id='inspirationalMenu'
		>
			<OpenMenu />
			{data && firstOpen && (
				<>
					<div
						id='menuBackdrop'
						className={cx(
							styles.menuBackdrop,
							applyStylesIf(open, styles.open),
							applyStylesIf(!open, styles.close)
						)}
						ref={ref}
						data-testid='inspirational.menuBackdrop'
					/>
					<Responsive onLargeOrGreater>
						<StructureLPlus />
					</Responsive>
					<Responsive onLessThanLarge>
						<StructureSM />
					</Responsive>
				</>
			)}
		</div>
	)
}
