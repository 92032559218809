export enum HoverDelayInspiration {
	BRANDS = 70,
	CLOSE_MENU = 300,
	DELAY_BACK_ACTION = 100,
	ENTRIES = 50,
	OUTSIDE = 50,
	TIME_PROTECTION_HOVER_OUTSIDE = 200,
	SUBBRANDS = 40,
}

export enum HoverDelayLegacy {
	BRANDS = 50,
	ENTRIES = 50,
}
