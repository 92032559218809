'use client'

import { useAnimationMenu } from 'header/hooks/useAnimationMenu'
import { useMenus } from 'header/hooks/useMenus'
import { useStructureHeight } from 'header/hooks/useStructureHeight'
import { useEffect, useRef } from 'react'
import { Keyboard } from 'types/keyboard'
import { cx } from 'utils/cx'

import { MenuRefType } from '../../../../context/MenuContext/MenuRefContext'
import { useIsLabelReady } from '../../../../hooks/useIsLabelReady'
import { getFirstAndLastElement } from '../../../../utils/menu/focusOrder/getFirstAndLastElement'
import { getFocusableElements } from '../../../../utils/menu/focusOrder/getFocusableElements'
import { handleEscapeKeyPress } from '../../../../utils/menu/focusOrder/handleEscapeKeyPress'
import { ColumnsLPlus } from '../Columns/ColumnsLPlus'
import { SubBrands } from '../SubBrands/SubBrands'
import { TopBar } from '../TopBar/TopBar'

import styles from './StructureLPlus.module.scss'

const ID_STRUCTURE = 'structureLPlus'
export const ID_STRUCTURE_CONTENT = `${ID_STRUCTURE}Content`

export function StructureLPlus() {
	const {
		open,
		brandSelected,
		subBrandSelected,
		updateOpen,
		getBrandSubBrands,
		getBrandMenu,
		hasDataLoaded,
		updateRefs,
	} = useMenus()

	const refContent = useRef<HTMLDivElement>(null)

	const { widthAside, selectedEntries, setSelectedEntries } =
		useAnimationMenu(brandSelected)

	const subBrands = getBrandSubBrands(brandSelected)
	const { menus, subLabel } = getBrandMenu(brandSelected, subBrandSelected)

	const pickLabel = !!menus ? menus[0].labelId : ''
	const isMenuLabelsReady = useIsLabelReady(pickLabel)

	const asideRef = useRef<HTMLElement>(null)
	const heightStructure = useStructureHeight(asideRef)

	useEffect(() => {
		const asideElement = asideRef.current
		if (open && asideElement && hasDataLoaded) {
			const { firstElement } = getFirstAndLastElement(asideElement)

			const tabListener = (event: KeyboardEvent) => {
				if (
					event.key === Keyboard.Tab &&
					event.shiftKey &&
					document.activeElement === firstElement
				) {
					event.preventDefault()
					const columnsWrapperElement = refContent.current?.children[
						refContent.current?.children.length - 1
					] as HTMLElement

					const firstColumnElement = columnsWrapperElement.children[0]
					const firstColumnLastElement = firstColumnElement.lastElementChild

					const focusElement = getFocusableElements(
						firstColumnLastElement as Element
					)[0]
					focusElement.focus()
				}
			}

			const escapeListener = (event: KeyboardEvent) =>
				handleEscapeKeyPress(event, updateOpen)

			asideElement.addEventListener('keydown', tabListener)
			asideElement.addEventListener('keydown', escapeListener)

			return () => {
				asideElement.removeEventListener('keydown', tabListener)
				asideElement.removeEventListener('keydown', escapeListener)
			}
		}
		return undefined
	}, [open, brandSelected, subBrandSelected, hasDataLoaded, selectedEntries])

	useEffect(() => {
		if (refContent.current) {
			updateRefs(MenuRefType.CONTENT, refContent)
		}
	}, [refContent.current])

	if (!isMenuLabelsReady) {
		return null
	}

	return (
		<aside
			id={ID_STRUCTURE}
			ref={asideRef}
			className={cx(
				styles.structure,
				open && styles.open,
				!open && styles.close
			)}
			style={
				{
					'--structure-width': `${widthAside}px`,
					'--structure-height': `${heightStructure}px`,
				} as React.CSSProperties
			}
		>
			<TopBar isSM={false} />
			<div
				id={ID_STRUCTURE_CONTENT}
				className={styles.content}
				ref={refContent}
			>
				{subBrands && <SubBrands subBrands={subBrands} />}
				<ColumnsLPlus
					menus={menus}
					subLabel={subLabel}
					selectedEntries={selectedEntries}
					setSelectedEntries={setSelectedEntries}
				/>
			</div>
		</aside>
	)
}
