'use client'

import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { StorePreviousUrl } from '../shared/StorePreviousUrl'

export function LoginClientLink({
	children,
	...linkProps
}: Omit<ClientLinkProps, 'href'>) {
	const LOGIN_URL = '/login'

	return (
		<>
			<StorePreviousUrl />
			<ClientLink {...linkProps} withLegacyLocale={false} href={LOGIN_URL}>
				{children}
			</ClientLink>
		</>
	)
}
