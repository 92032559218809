'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { createContext, useCallback, useState } from 'react'
import type { MasterData } from 'types/masterData'

export type HeaderState = {
	isMenuOpen: boolean
	isSearchOpen: boolean
	isForthColumnVisible: boolean
	brandId: MasterData['brand']
	subBrandId?: MasterData['brand']
}

const INITIAL_STATE: HeaderState = {
	isMenuOpen: false,
	isSearchOpen: false,
	isForthColumnVisible: false,
	brandId: null,
}

export type HeaderDispatch = (newState: Partial<HeaderState>) => void

const INITIAL_DISPATCH: HeaderDispatch = () => {
	/* */
}

type HeaderProviderProps = {
	children: React.ReactNode
}

export const HeaderStateContext = createContext<HeaderState>(INITIAL_STATE)
export const HeaderDispatchContext =
	createContext<HeaderDispatch>(INITIAL_DISPATCH)

export const HeaderProvider = ({ children }: HeaderProviderProps) => {
	const { brand: pageBrand, subBrand: pageSubBrand } = useMasterData()
	const [state, setState] = useState({
		...INITIAL_STATE,
		brandId: pageBrand,
		subBrandId: pageSubBrand,
	})
	const dispatch = useCallback(
		(newState: Partial<HeaderState>) =>
			setState((prevState) => ({ ...prevState, ...newState })),
		[]
	)

	return (
		<HeaderStateContext.Provider value={state}>
			<HeaderDispatchContext.Provider value={dispatch}>
				{children}
			</HeaderDispatchContext.Provider>
		</HeaderStateContext.Provider>
	)
}
