'use client'

import type { BrandEntry } from 'header/types/menu'
import { useEffect } from 'react'

import { useMenus } from '../../../../hooks/useMenus'
import { SubBrand } from './SubBrand'

import styles from './SubBrands.module.scss'

interface SubBrandsProps {
	subBrands: BrandEntry[]
}

export function SubBrands({ subBrands }: SubBrandsProps) {
	const { updateSubBrandSelected, subBrandSelected } = useMenus()
	const [firstSubBrand] = subBrands
	const defaultSubBrand =
		subBrandSelected === null ? firstSubBrand.id : subBrandSelected

	useEffect(() => {
		updateSubBrandSelected(defaultSubBrand)
	}, [])

	return (
		<div className={styles.suBrandsWrapper}>
			<ul className={styles.subBrands}>
				{subBrands.map((subBrand) => (
					<SubBrand key={subBrand.id} subBrand={subBrand} />
				))}
			</ul>
		</div>
	)
}
